
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import pagination from 'vue-bootstrap-pagination'
import {getBiboEndorsersForRepositioning}  from "@/api/bibo";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";
import BIBO_REPOSITION_ENDORSER from "@/views/BIBO_REPOSITION_ENDORSER.vue";
import RegisterEndorserModalBibo from "@/views/auth/RegisterEndorserModalBibo.vue";

export default defineComponent({
  name: "reorder",
  components: {
    // CompanyPolicy,
    // ECShopAdvisory,
    BIBO_REPOSITION_ENDORSER,
    RegisterEndorserModalBibo
  },
  data() {
    return { 
      list : [],
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12,
      },
       loading: true,
       slotdata : {},
      modalkey : 0,
    }
  },
  mounted(){
      this.reorderhistory();
      this.initSlotData();
  },
  methods : {
    async reorderhistory(){
      this.loading = true;
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          /* additional parameters */
        }
      };
      
      const response = await getBiboEndorsersForRepositioning(1);
      this.list = response.data.pageList;
      this.pagination = response.data.pagination;
      this.loading = false;
    },

    initSlotData() {
      var user = String(localStorage.getItem("ec_current_usid"));

      this.slotdata = {
          upid : user,
          spid : user,
          position : "L"
        };
      
      this.modalkey += 1;

    },
    registerNewEndorser(){
			    setTimeout(() => {    
					const btn = this.$refs.registerNewBiboButton as any;
					btn.click();
				}, 100);
		  } 
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("reorderTab") || "0");
      setCurrentPageTitle("BIBO Reposition");

    });
    
  }
});
