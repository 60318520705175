
import { defineComponent } from "vue";
import {processBiboEndorserRepositioning}  from "@/api/bibo";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from 'axios';


export default defineComponent({
  name: "encashmentprocess", 
  data(){
      return { 
        position_selected : "XXX",
        upid_input : "",
        isShowProceedBtn : false,

      }
  },
  methods: {
    async checkBoxValidator(event){
        if (event.target.checked) {
          this.isShowProceedBtn = true;
        } else {
          this.isShowProceedBtn = false;
        }
      },
      async process_repositioning(){
        
        var param = {
          usid : this.usid,
          upid : this.upid_input,
          position: this.position_selected
        }

        const response = await processBiboEndorserRepositioning(param);
        if(response != null){
          this.isShowProceedBtn = false;

          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            }); 
            window.location.reload();
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
            }); 
          }
        }
      }
  },
  mounted(){
     
  },
  components: {},
  props: {
    usid: String
  },
   setup(props, context) {
    const usid = props.usid;
   }
});
