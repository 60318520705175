import request from '@/utils/request'


export function getcountries() {
  return request({ url: 'publicdata/countries', method: 'get'})
}
export function getcities(country_id) {
  return request({ url: 'publicdata/cities?countryid=' + country_id, method: 'get'})
}

export function getaccountlist() {
  return request({ url: 'vgetaccountlist', method: 'get'})
}


export function getjobclassifications() {
  return request({ url: 'publicdata/jobclassifications', method: 'get'})
}
 


export function getoviage(params) {
  return request({ url: 'publicdata/getoviage', method: 'post', data: params })
} 


