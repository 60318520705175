import request from '@/utils/request'


export function confirm_register(data) {
  return request({ url: 'confirm_register', method: 'post', data: data})
} 

export function save_register(data) {
  return request({ url: 'save_register', method: 'post', data: data})
} 


export function validateMultiple(data) {
  return request({ url: 'validateMultiple', method: 'post', data: data})
} 

export function saveRegMultiple(data) {
  return request({ url: 'saveRegMultiple', method: 'post', data: data})
} 
